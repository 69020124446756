import Vue from 'vue';
import modals from '@/mixins/modals';
import NewStorage from '@/layouts/Stack/pages/Order/NewStorage';
import showErrorModal from '@/mixins/showErrorModal';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
export default {
  mixins: [modals, showErrorOpenStackModal],
  data() {
    return {
      container: '',
      typeContainer: 'private',
    };
  },
  methods: {
    newStorage(id) {
      this.id = id;
      const that = this;
      return new Promise(() => {
        this.$modals.open({
          name: 'NewStorage',
          size: 'medium',
          component: NewStorage,
          closeOnBackdrop: false,
          props: { id: id },
          on: {
            change: data => {
              this.container = data.name;
              this.typeContainer = data.type;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('confirm') },
              on: {
                click: async () => {
                  Vue.set(this.modal.footer.confirm.props, 'loading', true);
                  const payload = {
                    name: this.container,
                    type: this.typeContainer,
                  };
                  this.createContainer(payload)
                    .then(async () => {
                      that.$modals.close();
                      this.showResModal(`Контейнер <b>${this.container}</b> успешно создан.`);
                      await this.$store.dispatch('moduleStack/fetchProjectStorages');
                    })
                    .catch(e => {
                      Vue.set(this.modal.footer.confirm.props, 'loading', false);
                      let error;
                      if (e?.message === 'BucketAlreadyExists') {
                        error =
                          'Имя контейнера уже существует на другом проекте. Пожалуйста, выберите другое имя.';
                      } else error = e.message;

                      this.showErrorS3(error);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    async createContainer({ name, type }) {
      const params = {
        name: name,
        type: type,
      };
      await this.$store.dispatch('moduleStack/createS3Bucket', params);
    },
  },
};
