<template>
  <div class="tariffs-table">
    <tags-filter :search="search" @search-server="searchNetwork($event)"></tags-filter>
    <base-table
      :columns="tableHead"
      :list="paginList"
      :sort="sort"
      class="tariffs-table__table"
      @sort-change="onSortChange"
    >
      <tariffs-table-network-row
        v-for="item in paginList"
        :key="item.id"
        :filtred-search="searchFilter"
        :type="type"
        :columns="tableHead"
        :item="item"
        @click-more="$emit('click-more', item)"
      />
    </base-table>
    <NewPagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="list.length"
      type="base"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import TariffsTableNetworkRow from './TariffsTableNetworkRow';
import NewPagination from '@/components/Pagination/NewPagination';
import TagsFilter from '@/layouts/Stack/components/TagsFilter';
import pagination from '@/mixins/pagination';

export default {
  name: 'TariffsTableNetwork',
  components: {
    BaseTable,
    TagsFilter,
    TariffsTableNetworkRow,
    NewPagination,
  },
  mixins: [pagination],
  props: {
    type: {
      type: Boolean,
      default: false,
    },
    tableHead: {
      type: Array,
      default: () => [],
    },
    dataset: {
      type: Array,
      default: () => [],
    },
  },
  emits: { 'click-more': null },
  data() {
    return {
      search: '',
      searchFilter: '',
      /*stepOptions: [10, 25, 50, 100, 'all'],
      pagin: {
        start: 0,
        step: 10,
      },*/
    };
  },
  computed: {
    list() {
      // const nestedFilter = (targetArray, filters) => {
      //   let filterKeys = Object.keys(filters);
      //   return targetArray.filter(function (eachObj) {
      //     return filterKeys.every(function (eachKey) {
      //       if (!filters[eachKey].length) {
      //         return true;
      //       }
      //       return filters[eachKey].includes(eachObj[eachKey]);
      //     });
      //   });
      // };

      const network = this.$store.getters['moduleStack/GET_SORTED_LIST_NETWORK'].filter(x =>
        x.is_default ? x.shared === this.type && x.is_default === false : x.shared === this.type
      );
      // console.log(nestedFilter(network));
      if (this.$route.query.value)
        return network.filter(x =>
          x.name.toLowerCase().includes(this.$route.query.value.toLowerCase())
        );
      else return network;
    },
    sort() {
      return this.$store.state.moduleStack.sort.private;
    },
    /*paginList() {
      if (this.pagin.step === 'all') return this.list;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.list.slice(start, end);
    },*/
  },
  watch: {
    sort: {
      handler(val) {
        this.updateSort(val);
      },
      deep: true,
    },
    '$route.query.value': {
      handler: function (val) {
        this.searchFilter = val;
        this.search = val;
      },
      immediate: true,
    },
    searchFilter: {
      handler(val) {
        this.setFilter(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.updateSort(this.sort);
    this.search = '';
    this.setFilter(this.$route.query.value);
  },
  methods: {
    searchNetwork(event) {
      this.searchFilter = event;
    },
    setFilter(val) {
      this.searchFilter = val;
      this.search = val;
      this.$router
        .push({
          name: 'containerNetwork',
          query: { filter_by: 'name', value: val },
        })
        .catch(() => {});
    },
    /*setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },*/
    updateSort(sort) {
      const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
    onSortChange(payload) {
      let { prop, order } = payload;
      if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
      this.$store.commit('moduleStack/SET_NETWORK_SORT', { prop, order });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "name": "Сеть",
      "type": "Тип",
      "conditions": "Состояние",
      "shared": "Публичная",
      "config": "Конфигурация",
      "status": "Статус",
      "subnet": "Подсеть",
      "menu": "Меню"
    },
    "activeUnder": "До {date}",
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов",
    "prolong": "Продлить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
